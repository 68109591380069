import { nanoid } from "nanoid"
import Stars from "./Stars"

export default function Card(props) {
	const tagArray = props.tags.split(",")
	const tags = tagArray.map(tag => {
		return (
			<span className="tag is-info is-light" key={nanoid()}>{tag}</span>
		)
	})


	return (
		<div className="column is-4">
			<div className="card is-flex is-flex-direction-column" style={{ height: "100%" }}>
				<div className="card-image">
					<figure className="image is-3by2">
						{/* <figure className="image is-5by4"> */}
						<a href={props.link} target="_blank" rel="noreferrer">
							<img src={props.image} alt="Placeholder" />
							<div className="is-overlay is-flex is-justify-content-end is-align-items-end">
								<p className="p-2 has-text-white has-text-weight-bold">{props.runtime}</p>
							</div>
						</a>
					</figure>
				</div>
				<div className="card-content is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-space-between">
					<div className="level is-flex">
						<div className="level-left is-align-self-flex-start	">
							<Stars ratings={props.ratings} />
						</div>
						<div className="tags level-right" style={{ flexBasis: "70%" }}>{tags}</div>
					</div>
					<p className="title is-4">{props.title}</p>
					<a className="button is-link" href={props.link}>{props.offer}</a>
				</div>
			</div>
		</div>
	)
}
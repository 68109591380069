import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {nanoid} from "nanoid"
export default function Stars (props){
	
	let stars = []
	let total = props.ratings
	for (let i = 0; i < total; ++i) {
    	stars.push(<FontAwesomeIcon icon="star"  key={nanoid()}/>)
	}
	
	return (
		<p className="icon has-text-warning is-justify-content-start">
			{stars}
		</p>
	)
}
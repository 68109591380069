export default function Hero() {
	return (
		<section className="hero is-small is-primary has-text-centered">
		  <div className="hero-body">
		    <p className="title">
		      DP's Funny Lander
		    </p>
		    <p className="subtitle">
		      Explanation comes later
		    </p>
		  </div>
		</section>
	)
}
export const movieData = [
    {
        title: "Brazzers - The worlds biggest adult site, get access to over 10.000 movies",
        // image: "brazzers.png",
        image: "rebelpenguin_640x360.jpg",
        offer: "Get two days access for only 1$",
        runtime: "10:21",
        ratings: 5,
        link: "https://brazzers.com/?ats=eyJhIjoxNjM0MjksImMiOjQ2MTkwNTA1LCJuIjoxNCwicyI6OTAsImUiOjM0NywicCI6Mn0=&atc=Autocampaign_Default",
        tags: "Milf, Teen, Threesome"
    },
    {
        title: "BangBros",
        image: "BROS_640x360_2.jpg",
        // image: "digitalplayground.png",
        offer: "Get two days access for only 1$",
        runtime: "15:30",
        ratings: 4,
        link: "https://bangbros.com/?ats=eyJhIjoxNjM0MjksImMiOjQ2MTkwNTA1LCJuIjoxMzAsInMiOjY5MywiZSI6MTA2NzAsInAiOjExfQ==&atc=Autocampaign_Default",
        tags: "gay, lesbian, lgbtq"
    },
    {
        title: "Mofos - one of the top adult networks in the world with true quality HD videos.",
        image: "MF_640x360_1.jpg",
        // image: "mofos.png",
        offer: "Get two days access for only 1$",
        runtime: "15:30",
        ratings: 3,
        link: "https://www.mofos.com/tour/home/?ats=eyJhIjoxNjM0MjksImMiOjQ2MTkwNTA1LCJuIjoxNSwicyI6MTc1LCJlIjo1NjIsInAiOjExfQ==&atc=Autocampaign_Default",
        tags: "Mom"
    },
    // {
    //     title: "Reality Kings - No bullshit, just high quality porn! ",
    //     image:"realitykings.png",
    //     offer: "Get two days access for only 1$",
    //     runtime: "15:30",
    //     ratings: 5,
    //     link: "https://landing.rk.com/?ats=eyJhIjoxNjM0MjksImMiOjYwNjkzMDIxLCJuIjoyMCwicyI6MzU4LCJlIjo4OTgzLCJwIjoxMX0=",
    //     tags: "gay, lesbian, lgbtq"
    // },
    // {
    //     title: "Hentainpros",
    //     image: "hentai2.jpg",
    //     plot: "I don't know, but the flag is a big plus!",
    //     runtime: "15:30",
    //     ratings: 5,
    //     link: "https://landing.hentaiprosnetwork.com/?ats=eyJhIjoxNjM0MjksImMiOjYwNjkzMDIxLCJuIjoyNSwicyI6NTM3LCJlIjo4OTk0LCJwIjoxMX0=",
    //     tags: "gay, lesbian, lgbtq"
    // },
    // {
    //     title: "Fakehub",
    //     image: "fakehub.png",
    //     plot: "I don't know, but the flag is a big plus!",
    //     runtime: "15:30",
    //     ratings: 5,
    //     link: "https://landing.fakehub.com/?ats=eyJhIjoxNjM0MjksImMiOjYwNjkzMDIxLCJuIjozMCwicyI6MjYxLCJlIjo5MTgwLCJwIjoxMX0=",
    //     tags: "gay, lesbian, lgbtq"
    // },
    //  {
    //     title: "Spicevidz",
    //     image: "spicevidz.png",
    //     plot: "I don't know, but the flag is a big plus!",
    //     runtime: "15:30",
    //     ratings: 5,
    //     link: "https://landing.spicevids.com/affiliates/?ats=eyJhIjoxNjM0MjksImMiOjYwNjkzMDIxLCJuIjoxMjAsInMiOjY4MCwiZSI6MTA0MzIsInAiOjExfQ==",
    //     tags: "gay, lesbian, lgbtq"
    // },
    // {
    //     title: "Transangels",
    //     image: "transangels.png",
    //     plot: "I don't know, but the flag is a big plus!",
    //     runtime: "15:30",
    //     ratings: 5,
    //     link: "https://landing.transangelsnetwork.com/?ats=eyJhIjoxNjM0MjksImMiOjYwNjkzMDIxLCJuIjozNSwicyI6NTQxLCJlIjo5MTEzLCJwIjoxMX0=",
    //     tags: "gay, lesbian, lgbtq"
    // },
]